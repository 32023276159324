import React from 'react';
import styled from '@emotion/styled';
import NavLink from './NavLink';
import { breakpointUp } from '../styles/mq';
import { fontSize, fontWeight, spacer } from '../styles/variables';

const StyledPagination = styled.div`
	display: flex;
	margin-top: ${spacer['9']};
	justify-content: space-between;
	align-items: center;

	${breakpointUp('sm')} {
		justify-content: flex-start;
	}

	${breakpointUp('md')} {
		margin-top: ${spacer['11']};
	}
`;

const StyledCounter = styled.span`
	padding-left: ${spacer['5']};
	padding-right: ${spacer['5']};
	font-size: ${fontSize.xs};
	font-weight: ${fontWeight.bold};

	${breakpointUp('sm')} {
		padding-left: ${spacer['8']};
		padding-right: ${spacer['8']};
	}
`;

type Props = {
	to: string;
	hasLangPrefix?: boolean;
	currentPage: number;
	pagesCount: number;
	prevLabel: string;
	nextLabel: string;
};

const Pagination: React.FC<Props> = ({ to, currentPage, pagesCount, prevLabel, nextLabel, hasLangPrefix = true }) => {
	if (pagesCount < 2) {
		return null;
	}

	return (
		<StyledPagination>
			<NavLink
				to={currentPage > 2 ? `${to}${currentPage - 1}/` : to}
				title={prevLabel}
				arrowDirection="left"
				isDisabled={currentPage < 2}
				hasLangPrefix={hasLangPrefix}
			/>
			<StyledCounter>
				{currentPage}/{pagesCount}
			</StyledCounter>
			<NavLink
				to={currentPage < pagesCount ? `${to}${currentPage + 1}/` : to}
				title={nextLabel}
				arrowDirection="right"
				isDisabled={currentPage >= pagesCount}
				hasLangPrefix={hasLangPrefix}
			/>
		</StyledPagination>
	);
};

export default Pagination;
