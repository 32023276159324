import React from 'react';
import { PageCategoryStructure, PageProps } from '../typing/Page';
import Layout from '../components/Layout';
import { ProjectList } from '../typing/Project';
import { getTranslation } from '../utils/translations';
import ProjectCard from '../components/ProjectCard';
import ListGrid from '../components/ListGrid';
import { graphql } from 'gatsby';
import Pagination from '../components/Pagination';
import CategoriesNav from '../components/CategoriesNav';
import PageTitle from '../components/PageTitle';
import { css } from '@emotion/core';
import { spacer } from '../styles/variables';
import { breakpointUp } from '../styles/mq';
import { replaceCategoryName } from '../utils/strings';

const ProjectsCategoriesCss = css`
	margin-bottom: ${spacer['8']};

	${breakpointUp('md')} {
		margin-bottom: ${spacer['10']};
	}
`;

type ProjectsData = {
	projectsCategories: PageCategoryStructure[];
	projects: ProjectList[];
	currentPage: number;
	pagesCount: number;
	currentCategory?: string;
};

const ProjectsTemplate: React.FC<PageProps<ProjectsData, GatsbyTypes.ProjectsQuery>> = ({ path, data, pageContext }) => {
	const projectsStructure = pageContext.structure.find(item => item.name === 'projects');
	const projectsCategories = pageContext.data.projectsCategories;
	if (!projectsStructure) {
		return null;
	}

	const category = pageContext.data.currentCategory ? projectsCategories.find(category => category.name === pageContext.data.currentCategory) : null;

	return (
		<Layout
			path={path}
			pageContext={pageContext}
		>
			<PageTitle
				title={projectsStructure.title}
				subtitle={category ? replaceCategoryName(category.title) : getTranslation(data, 'all')}
			/>
			{projectsCategories && (
				<CategoriesNav
					categories={projectsCategories}
					label={getTranslation(data, 'show')}
					css={ProjectsCategoriesCss}
					activeCategory={pageContext.data.currentCategory}
				/>
			)}
			<ListGrid
				cols={1}
				rCols={{ sm: 2, lg: 3 }}
				noItemsMessage={getTranslation(data, 'noProjects')}
			>
				{pageContext.data.projects.map((project, index) => {
					const imgDef = data.projectsImages.edges.find(edge => edge.node.relativePath === project.coverImage);
					return (
						<ProjectCard
							key={project.slug}
							project={project}
							image={imgDef?.node.childImageSharp?.gatsbyImageData}
							imageLoading={index < 3 ? 'eager' : 'lazy'}
						/>
					);
				})}
			</ListGrid>
			<Pagination
				// to={`${projectsStructure.slug}${category ? `/${category.slug}`}`}
				to={category ? category.slug : projectsStructure.slug}
				currentPage={pageContext.data.currentPage}
				pagesCount={pageContext.data.pagesCount}
				prevLabel={getTranslation(data, 'prev')}
				nextLabel={getTranslation(data, 'next')}
			/>
		</Layout>
	);
};

// breakpoints: [272, 544, 816, 1088]

export const query = graphql`
	query Projects($lang: String!, $projectsImages: [String!]) {
		translations: allFile(filter: { name: { eq: $lang }, extension: { eq: "json" } }) {
			nodes {
				childTranslationsJson {
					all
					prev
					next
					show
					noProjects
				}
			}
		}
		projectsImages: allFile(filter: { relativePath: { in: $projectsImages } }) {
			edges {
				node {
					id
					relativePath
					childImageSharp {
						gatsbyImageData(width: 544, height: 306, breakpoints: [272, 544, 816], quality: 80, placeholder: BLURRED, layout: CONSTRAINED)
					}
				}
			}
		}
	}
`;

export default ProjectsTemplate;
